<template>
  <v-main>
    <v-container fluid class="justify-center align-center fill-height gradientBackground">
      <!--      definir si se habilita telemedicina,  listado de dias feriados, y listado de establecimientos con sus respectivos horarios de atencion -->

      <v-row class="justify-center">
        <v-col cols="12" md="8">
          <v-card class="rounded-xl">
            <v-toolbar card color="white" elevation="1">
              <v-icon left>mdi-cog</v-icon>
              <v-toolbar-title>Configuración</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-title class="ma-2">
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="configuraciones"
                item-key="id"
                class="elevation-1"
                :search="search"
                :footer-props="{
                      itemsPerPageText: 'Filas por página:',
                      itemsPerPageOptions: [5, 10, 25, 50],
                      pageText: '{0}-{1} de {2}',
                    }"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon  class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>

import {getGeneralParams} from "@/helpers/api/horas_medicas";

export default {
  name: "Configuracion",
  data: () => {
    return {
      search: "",
      configuraciones: [],
      configuracion: {
        id: null,
        nombre: "",
        descripcion: "",
        estado: true,
      },
      dialog: false,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
        {text: "Descripción", value: "description"},
        {text: "Valor", value: "value"},
        {text: "Acciones", value: "actions", sortable: false},
      ],
    }
  },
  mounted() {
    this.getParametrosGenerales();
  },
  methods: {
    getParametrosGenerales() {
      getGeneralParams().then((response) => {
        console.log(response);
        this.configuraciones = response;
      });
    },

  }
}
</script>

<style scoped>
.gradientBackground {
  background: linear-gradient(45deg, #318BFD 0%,  #318BFD 100%);
  overflow-y: scroll;
}
</style>
