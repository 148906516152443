import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,[_c(VContainer,{staticClass:"justify-center align-center fill-height gradientBackground",attrs:{"fluid":""}},[_c(VRow,{staticClass:"justify-center"},[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c(VCard,{staticClass:"rounded-xl"},[_c(VToolbar,{attrs:{"card":"","color":"white","elevation":"1"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-cog")]),_c(VToolbarTitle,[_vm._v("Configuración")]),_c(VSpacer)],1),_c(VCardTitle,{staticClass:"ma-2"},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.configuraciones,"item-key":"id","search":_vm.search,"footer-props":{
                    itemsPerPageText: 'Filas por página:',
                    itemsPerPageOptions: [5, 10, 25, 50],
                    pageText: '{0}-{1} de {2}',
                  }},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c(VIcon,{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }