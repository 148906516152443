import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,[_c(VContainer,{staticClass:"justify-center align-center fill-height gradientBackground",attrs:{"fluid":""}},[_c(VRow,{staticClass:"justify-center"},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VCard,{staticClass:"rounded-xl"},[_c(VToolbar,{attrs:{"card":"","color":"white","elevation":"1"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-clipboard-multiple")]),_c(VToolbarTitle,[_vm._v("Especialidades")]),_c(VSpacer),_c(VToolbarItems,[_c('div',{staticClass:"justify-center align-center d-flex"},[_c(VBtn,{attrs:{"fab":"","color":"primary","small":""},on:{"click":function($event){_vm.dialog = true}}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1)])],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.especialidades,"item-key":"id","loading":_vm.loading,"loading-text":"Cargando... Por favor espere","footer-props":{
                    itemsPerPageText: 'Filas por página:',
                    itemsPerPageOptions: [5, 10, 25, 50],
                    pageText: '{0}-{1} de {2}',
                  }},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1),_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12"}},[_c(VTextField,{attrs:{"label":"Nombre","outlined":"","dense":""},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12"}},[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Medicos Asociados")])]),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headersMedicos,"items":_vm.editedItem.doctors,"item-key":"id","loading":_vm.loading,"loading-text":"Cargando... Por favor espere","footer-props":{
                      itemsPerPageText: 'Filas por página:',
                      itemsPerPageOptions: [5, 10, 25, 50],
                      pageText: '{0}-{1} de {2}',
                    }},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showMedico(item)}}},[_vm._v(" mdi-pencil ")])]}}])}),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.showMedicos}},[_vm._v("Ver Medicos")])],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }