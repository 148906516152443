import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"10","sm":"6","md":"4"}},[_c(VCard,{staticClass:"elevation-24 rounded-xl background-login"},[_c(VWindow,{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c(VWindowItem,{attrs:{"value":1}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCardText,{staticClass:"mt-12"},[_c(VImg,{staticClass:"mx-auto",attrs:{"src":require("@/assets/logoIduam.svg"),"max-width":"300"}}),_c('h3',{staticClass:"text-center my-4 white--text font-weight-light"},[_vm._v("El futuro de la medicina está en tu hogar")]),_c(VForm,[_c(VTextField,{staticClass:"inputForm",attrs:{"name":"Email","rules":_vm.emailRules,"type":"text","background-color":"white","required":"","prepend-inner-icon":"mdi-account","outlined":"","dense":"","filled":"","color":"primary","placeholder":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{staticClass:"inputForm",attrs:{"id":"password","name":"password","rules":_vm.passwordRules,"prepend-inner-icon":"mdi-lock","type":"password","required":"","color":"primary","outlined":"","append-icon":_vm.passType ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.passType ? 'password' : 'text',"background-color":"white","dense":"","placeholder":"Contraseña"},on:{"click:append":() => (_vm.passType = !_vm.passType)},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('h3',{staticClass:"text-center mt-4 white--text font-weight-light"},[_vm._v("¿Olvidaste tu contraseña?")])],1),_c('div',{staticClass:"text-center my-3 white--text"},[_c(VBtn,{staticClass:"blue--text rounded-lg",attrs:{"elevation":"0"},on:{"click":_vm.loginUser}},[_vm._v("Ingresar ")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }